<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="relief-primary"
        @click="addOrganization()"
        class="mb-2"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t("navigation.add_button", { name: $route.meta.pageTitle }) }}
      </b-button>
    </div>
    <BsTable
      tableName="organization"
      :fields="tableFields"
      :actions="actions"
      :has_paginator="false"
      :show_filter="true"
    />
    <base-modal />
  </div>
</template>

<script>
import BsTable from "@/layouts/components/BsTable.vue";
import custom from "@/custom";
import moment from "moment";
import { BButton } from "bootstrap-vue";
import BaseModal from "@/layouts/components/BaseModal.vue";

export default {
  components: {
    BsTable,
    BButton,
    BaseModal,
  },

  data() {
    return {
      tableFields: [
        {
          key: "id",
          label: this.$t("common.table.fields.id"),
          sortable: true,
        },
        {
          key: "name",
          label: this.$t("common.table.fields.name"),
          sortable: true,
        },
        {
          key: "created_by",
          label: this.$t("common.table.fields.created_at"),
          sortable: true,
        },
        {
          key: "role",
          label: this.$t("organization.table.fields.your_role"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("common.table.fields.created_at"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("common.table.fields.actions"),
        },
      ],
      tableItems: [],
      perPage: 10,
      tags: "",
      actions: custom.tableActions.organization,
      reloadTable: false,
      totalRows: 0,
    };
  },

  created() {
    this.$root.$refs[this.$route.meta.pageTitle] = this;
  },

  methods: {
    addOrganization() {
      this.$store
        .dispatch("setModalObj", "addOrganization")
        .then(() => {
          this.$store.dispatch("setActiveModal", true);
        })
        .catch(() => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>